import { Badge } from '@/app/atoms/badge';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { useMeasurement } from '@/app/hooks/use-measure';
import { fuzzyIncludes } from '@/app/utils/string';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps, InheritableProps } from '@/types/utilties';
import { ReactComponent as AiLogo } from '@assets/parts/ai-logo.svg';
import { CloseButton, Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
	ArrowsPointingInIcon,
	ArrowUturnLeftIcon,
	ChevronRightIcon,
	Cog6ToothIcon,
	EllipsisVerticalIcon,
	EyeIcon,
	EyeSlashIcon,
	MagnifyingGlassIcon,
	MinusIcon,
	PlusIcon
} from '@heroicons/react/24/solid';
import { Highlight, Indicator, Loader, Menu, Switch } from '@mantine/core';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { isNil, sortBy, sum, uniqBy } from 'lodash-es';
import { Fragment, ReactNode, SetStateAction, Suspense, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Camera, MOUSE, TOUCH } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { DEFAULT_DISTANCE_Z, MAX_MIN_DISTANCE_Z, MESH_SCALE } from '../../constants';
import { useImageSizes } from '../../hooks/use-image-dimensions';
import { useSearchValue } from '../../hooks/use-search-value';
import { CategoryTree, CategoryTreeLeaf, Diagram, PartsFormData } from '../../types';
import { categoryLeaves } from '../../utils';
import { vec3 } from '../../utils/geometry';
import { meshAreaSortKey, meshKindSortKey, relativeMesh } from '../../utils/mesh';
import {
	PartsDiagramBackground,
	PartsDiagramCodeLayer,
	PartsDiagramFallback,
	PartsDiagramHotspot,
	PartsDiagramNexusLine,
	PartsDiagramSegment
} from './mesh';

const optimalMeshScale = (height: number, navHeight: number) => {
	const canvasHeight = window.innerHeight - navHeight;
	return (canvasHeight * MESH_SCALE) / height;
};

type PartsDiagramRendererProps = InheritableProps<
	typeof Canvas,
	{
		diagram?: Diagram | null;
		hightlighted?: string | null;
		selection: PartsFormData;
		hide?: boolean;
		actions: {
			part: {
				highlight: (partSlotId: string) => void;
			};
			controls: {
				start: (control: OrbitControlsImpl) => void;
				change: (control: Camera) => void;
			};
		};
		children?: ReactNode;
	}
>;

export const PartsDiagramRenderer = ({
	className,
	diagram,
	hightlighted,
	selection,
	actions,
	hide,
	...rest
}: PartsDiagramRendererProps) => {
	const q = useSearchValue();
	const [errors, setErrors] = useState<Record<string, Error>>({});
	const { value: nav } = useMeasurement('navigation-bar');
	const imageSize = useImageSizes(diagram?.image?.full ?? null);

	const sizes = useMemo(() => {
		if (!imageSize || !nav) {
			return null;
		}
		return {
			width: imageSize.naturalWidth,
			height: imageSize.naturalHeight,
			ratio: imageSize.naturalHeight / imageSize.naturalWidth,
			scale: optimalMeshScale(imageSize.naturalHeight, nav.height)
		};
	}, [imageSize, nav]);

	const segmented = useMemo(
		() =>
			diagram?.partSlots?.some(({ meshes }) => meshes.some(mesh => mesh.kind === 'polygon')) ??
			false,
		[diagram]
	);

	const error = useMemo(() => (diagram ? errors?.[diagram.image.full] : null), [errors, diagram]);

	const meshes = useMemo(() => {
		if (!sizes || !diagram) {
			return [];
		}
		const meshes = diagram.partSlots.flatMap(({ meshes, ...rest }) =>
			meshes.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ ...rest, mesh }))
		);

		return sortBy(
			meshes,
			({ mesh }) => meshKindSortKey(mesh),
			({ mesh }) => meshAreaSortKey(mesh)
		);
	}, [diagram, sizes]);

	return (
		<>
			<Canvas
				className={tlsx('w-full h-full overflow-hidden', className)}
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px - 0.5rem)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 0.5rem)`
				}}
				{...rest}
			>
				{diagram && sizes && (
					<>
						<ErrorBoundary
							fallbackRender={() => (
								// todo (vincent) some images are not cors configured properly
								// this is decent fallback/render image as image (only caveat no hotspots)
								<PartsDiagramFallback
									key={diagram.id}
									url={diagram.image.full}
									ratio={sizes.ratio}
									scale={sizes.scale}
									filter={hide || !segmented ? 'opaque' : hightlighted ? 'dimmed' : 'background'}
								/>
							)}
							onError={err => setErrors(errors => ({ ...errors, [diagram.image.full]: err }))}
						>
							<PartsDiagramBackground
								key={diagram.id}
								url={diagram.image.full}
								ratio={sizes.ratio}
								scale={sizes.scale}
								filter={hide || !segmented ? 'opaque' : hightlighted ? 'dimmed' : 'background'}
							/>
						</ErrorBoundary>
						{!hide &&
							meshes.map(({ assemblies, id, code, mesh }, index) => {
								const highlighted = hightlighted === id;
								const added = assemblies.some(({ id }) => (selection[id]?.quantity ?? 0) > 0);
								const searched = q
									? assemblies.some(({ searchables }) =>
											searchables.some(searchable => fuzzyIncludes(searchable, q))
										)
									: false;
								const onClick = () => actions.part.highlight(id);

								switch (mesh.kind) {
									case 'whiteout': {
										return (
											<PartsDiagramCodeLayer key={`${id}-whiteout-${index}`} rect={mesh.rect} />
										);
									}
									case 'line': {
										return (
											<PartsDiagramNexusLine
												key={`${id}-line-${index}`}
												from={mesh.from}
												to={mesh.to}
												highlighted={highlighted}
												checked={added}
											/>
										);
									}
									case 'polygon': {
										return (
											<PartsDiagramSegment
												key={`${id}-polygon-${index}`}
												url={diagram.image.full}
												ratio={sizes.ratio}
												scale={sizes.scale}
												polygon={mesh.polygon}
												position={vec3(0, 0, 0)}
												highlighted={highlighted}
												checked={added}
												searched={searched}
												renderOrder={index + 5}
												onClick={onClick}
											/>
										);
									}
									case 'hotspot': {
										return (
											<PartsDiagramHotspot
												key={`${id}-hotspot-${index}`}
												code={code}
												point={mesh.point}
												checked={added}
												highlighted={highlighted}
												searched={searched}
												onClick={onClick}
											/>
										);
									}
								}
							})}
					</>
				)}
				<OrbitControls
					position={[0, 0, 0]}
					ref={orbitControls => {
						if (!orbitControls) {
							return;
						}
						actions.controls.start(orbitControls);
					}}
					enableDamping
					enableRotate={false}
					dampingFactor={0.25}
					zoomSpeed={1.25}
					zoomToCursor
					maxDistance={DEFAULT_DISTANCE_Z + MAX_MIN_DISTANCE_Z}
					minDistance={DEFAULT_DISTANCE_Z - MAX_MIN_DISTANCE_Z}
					mouseButtons={{
						LEFT: MOUSE.PAN,
						MIDDLE: MOUSE.PAN
					}}
					touches={{
						ONE: TOUCH.PAN,
						TWO: TOUCH.DOLLY_PAN
					}}
					onChange={event => {
						if (!event) {
							return;
						}
						actions.controls.change(event.target.object);
					}}
				/>
			</Canvas>

			{segmented && (
				<div className="absolute bottom-6 right-6 z-[5]">
					<Badge size="small" rounded variant="purple">
						<Badge.LeadingIcon as={AiLogo} />
						Interactive diagram
					</Badge>
				</div>
			)}

			{!isNil(error) && (
				<div className="absolute bottom-6 right-6 z-[5]">
					<Badge size="small" rounded variant="red">
						<Badge.LeadingIcon as={Cog6ToothIcon} />
						Fallback diagram
					</Badge>
				</div>
			)}
		</>
	);
};

export const PartsDiagramSuspenseRenderer = ({ className, ...rest }: PartsDiagramRendererProps) => (
	<Suspense
		fallback={
			<div key="parts-diagram-loader" className={tlsx('w-full grid place-items-center', className)}>
				<Loader variant="bars" />
			</div>
		}
	>
		<PartsDiagramRenderer className={className} {...rest} />
	</Suspense>
);

type PartsDiagramNavigationProps = InheritableElementProps<
	'div',
	{
		categories: CategoryTree[];
		other?: CategoryTreeLeaf | null;
		selected?: CategoryTreeLeaf | null;
		diagram?: Diagram | null;
		selection: PartsFormData;
		open: boolean;
		actions: {
			view: {
				set: (action: SetStateAction<boolean>) => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsDiagramNavigation = ({
	className,
	categories,
	other,
	selected: selectedCategory,
	diagram: selectedDiagram,
	selection,
	open,
	actions,
	...rest
}: PartsDiagramNavigationProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	useEffect(() => {
		if (!selectedCategory || !selectedDiagram) {
			actions.view.set(true);
		}
	}, [selectedCategory, selectedDiagram]);

	return (
		<>
			<div
				className={tlsx('absolute top-4 left-4 flex gap-4 z-20', {
					'inset-4 bottom-0': !selectedCategory || !selectedDiagram
				})}
			>
				<div
					className={tlsx(
						'h-fit flex flex-col rounded-lg shadow border bg-white p-2 flex-shrink-0 transition-all duration-300 overflow-auto text-base font-semibold',
						{ '!w-80 shadow-none': open },
						className
					)}
					style={{
						width: selectedCategory
							? `${Math.min(20, selectedCategory.description.length * 0.75 + 3)}rem`
							: '18rem',
						maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 2.5rem)`
					}}
					{...rest}
				>
					<button
						type="button"
						className="group flex items-center justify-between py-1.5 px-3 text-start"
						onClick={() => {
							if (!selectedCategory || !selectedDiagram) {
								return;
							}
							actions.view.set(prev => !prev);
						}}
					>
						<h2 className="text-gray-900 text-base font-semibold">
							{selectedCategory?.description ?? 'Assemblies'}
						</h2>
						<div className="flex items-center p-1 rounded text-sm group-hover:bg-gray-50 group-active:bg-gray-50 select-none">
							<ChevronRightIcon
								className={tlsx('w-4 h-4 transition-all', { 'rotate-180': open })}
							/>
						</div>
					</button>

					{open && (
						<div className="w-full p-2 empty:hidden">
							{categories.map(c => (
								<PartsDiagramCategoryTree
									key={c.id}
									category={c}
									selected={selectedCategory}
									selection={selection}
									onClick={actions.category.set}
								/>
							))}
							{other && (
								<div className="w-full flex items-center justify-end pt-4 px-4">
									<button
										type="button"
										className="text-blue-600 text-sm w-fit font-medium text-start"
										onClick={() => actions.category.set(other)}
									>
										View other diagrams
									</button>
								</div>
							)}
						</div>
					)}
				</div>

				{selectedCategory && !selectedDiagram && (
					<div
						className="flex-1 h-max flex flex-wrap gap-3 overflow-auto"
						style={{
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 2rem)`
						}}
					>
						{selectedCategory.diagrams.map(diagram => (
							<PartsDiagramPreview
								key={diagram.id}
								diagram={diagram}
								selection={selection}
								onClick={() => {
									actions.view.set(false);
									actions.diagram.set(diagram);
								}}
							/>
						))}
					</div>
				)}
			</div>

			{!!selectedCategory?.diagrams?.length && !selectedDiagram && (
				<div className="absolute inset-0 z-[15] backdrop-blur-sm bg-[#FCFDFD]" />
			)}
		</>
	);
};

type PartsDiagramCategoryTreeProps = InheritableElementProps<
	'button',
	{
		category: CategoryTree;
		selected?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		onClick: (category: CategoryTreeLeaf) => void;
	}
>;

export const PartsDiagramCategoryTree = ({
	className,
	category,
	selected,
	selection,
	onClick,
	...rest
}: PartsDiagramCategoryTreeProps) => {
	const q = useSearchValue();
	const [open, setOpen] = useState(false);
	const leaves = useMemo(() => categoryLeaves(category), [category]);
	const count = useMemo(() => {
		const assemblies = uniqBy(
			leaves
				.flatMap(({ diagrams }) => diagrams)
				.flatMap(({ partSlots }) => partSlots)
				.flatMap(({ assemblies }) => assemblies),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [leaves, selection]);

	const defaultOpen = useMemo(
		() => leaves.some(({ id }) => selected?.id === id),
		[leaves, selected]
	);

	useEffect(() => {
		if (defaultOpen) {
			setOpen(true);
		}
	}, [selected]);

	if (category.kind === 'leaf') {
		return (
			<button
				type="button"
				className={tlsx(
					'flex items-center text-base w-full font-medium text-start text-gray-800 ml-3 px-3 py-1 rounded-md hover:bg-gray-50 active:bg-gray-50 2 mt-3 first:mt-0',
					{
						'bg-blue-600 text-white hover:bg-blue-600 active:bg-blue-600':
							selected?.id === category.id
					},
					className
				)}
				onClick={() => onClick(category)}
			>
				<Highlight
					highlight={q ?? ''}
					highlightStyles={{
						'--tw-text-opacity': 1,
						color: 'rgb(0 0 0 / var(--tw-text-opacity))'
					}}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span
						className={tlsx('ml-3 text-sm leading-none font-medium text-blue-600 min-w-[2ch]', {
							'text-white': selected?.id === category.id
						})}
					>
						{count}
					</span>
				)}
			</button>
		);
	}
	return (
		<div className="mt-3 first:mt-0">
			<button
				type="button"
				className={tlsx(
					'group flex w-full items-center gap-2 text-start hover:bg-gray-50 active:bg-gray-50 py-1 px-2',
					className
				)}
				onClick={() => setOpen(prev => !prev)}
				{...rest}
			>
				<ChevronRightIcon className="w-3 h-3 group-data-[open]:rotate-90" />
				<Highlight
					component="span"
					className="text-base text-start font-medium text-gray-800"
					highlight={q ?? ''}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span className="ml-1 text-sm leading-none font-medium text-blue-600 min-w-[2ch]">
						{count}
					</span>
				)}
			</button>
			<div className={tlsx('mt-3 pl-4', { hidden: !open })}>
				{category.assemblies.map(sub => (
					<PartsDiagramCategoryTree
						key={sub.id}
						category={sub}
						selected={selected}
						selection={selection}
						onClick={onClick}
					/>
				))}
			</div>
		</div>
	);
};

type PartsDiagramPreviewProps = InheritableElementProps<
	'button',
	{
		diagram: Diagram;
		selection: PartsFormData;
		onClick: () => void;
	}
>;

export const PartsDiagramPreview = ({
	className,
	diagram,
	selection,
	onClick,
	...rest
}: PartsDiagramPreviewProps) => {
	const q = useSearchValue();
	const count = useMemo(() => {
		const assemblies = uniqBy(
			diagram.partSlots.flatMap(({ assemblies }) => assemblies),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [diagram, selection]);
	return (
		<Indicator
			className="text-sm"
			zIndex={90}
			disabled={count === 0}
			offset={16}
			size="1.5rem"
			label={`${count}`}
		>
			<button
				type="button"
				className={tlsx(
					'flex flex-col items-center justify-between gap-2 py-3 px-5 bg-white border rounded-lg w-56 h-64 xl:w-60 xl:h-72',
					className
				)}
				onClick={onClick}
				{...rest}
			>
				<div className="flex-1 grid place-items-center/">
					<ImageWithSkeleton
						className="w-48 h-48 xl:w-52 xl:h-52 object-scale-down"
						src={diagram.image.thumb}
						loading="lazy"
					/>
				</div>
				<Highlight
					component="span"
					className="text-xs font-medium text-gray-900"
					highlight={q ?? ''}
				>
					{`${diagram.code}: ${diagram.description}`}
				</Highlight>
			</button>
		</Indicator>
	);
};

type PartsDiagramToolbarProps = InheritableElementProps<
	'div',
	{
		category: CategoryTreeLeaf;
		diagram: Diagram;
		zoom: number;
		actions: {
			diagram: { set: (diagram: Diagram) => void };
			zoom: {
				in: () => void;
				out: () => void;
			};
			hide: (hide: boolean) => void;
			menu: {
				custom: {
					add: () => void;
				};
				diagram: {
					recenter: () => void;
					resetZoom: () => void;
					resetAll: () => void;
				};
			};
		};
	}
>;

export const PartsDiagramToolbar = ({
	className,
	category,
	diagram,
	zoom,
	actions,
	...rest
}: PartsDiagramToolbarProps) => {
	return (
		<div
			className={tlsx(
				'absolute bottom-6 flex items-center min-w-[20rem] rounded-lg shadow-md border px-1 bg-white z-10',
				className
			)}
			{...rest}
		>
			<Popover className="relative">
				<PopoverButton
					type="button"
					className="flex items-center px-2 py-1 rounded gap-3 text-sm hover:bg-gray-100 active:bg-gray-100"
				>
					<span className="text-sm">{diagram.description}</span>
					<ChevronDownIcon className="w-4 h-4" />
				</PopoverButton>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 translate-y-1"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 translate-y-1"
				>
					<PopoverPanel
						anchor="top"
						className={tlsx(
							'grid grid-cols-3 overflow-auto [--anchor-gap:0.75rem] p-2 gap-2 rounded-md bg-white border shadow-md z-20',
							{
								'grid-cols-1': category.diagrams.length == 1,
								'grid-cols-2': category.diagrams.length == 2,
								'grid-cols-3': category.diagrams.length % 3 === 0,
								'grid-cols-4': category.diagrams.length % 4 === 0,
								'grid-cols-5': category.diagrams.length % 5 === 0
							}
						)}
					>
						{category.diagrams.map(each => (
							<CloseButton
								type="button"
								key={each.id}
								className={tlsx('rounded w-28 h-28 overflow-clip border bg-white flex-shrink-0', {
									'border-2 border-blue-600': diagram.id === each.id
								})}
								onClick={() => {
									return actions.diagram.set(each);
								}}
							>
								<ImageWithSkeleton
									className="w-28 h-28 object-scale-down"
									src={each.image.thumb}
									loading="lazy"
								/>
							</CloseButton>
						))}
					</PopoverPanel>
				</Transition>
			</Popover>
			<span className="w-px h-10 bg-gray-200 mx-2.5" />
			<div className="flex items-center py-1 px-1 gap-1.5">
				<button
					type="button"
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.in}
				>
					<PlusIcon className="w-4 h-4" />
				</button>
				<span className="text-sm min-w-[4ch] text-end select-none">{zoom}%</span>
				<button
					type="button"
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.out}
				>
					<MinusIcon className="w-4 h-4" />
				</button>
			</div>
			<Switch
				className="mx-3"
				onChange={e => actions.hide(e.target.checked)}
				onLabel={<EyeSlashIcon className="w-4 h-4" />}
				offLabel={<EyeIcon className="w-4 h-4" />}
			/>
			<Menu shadow="md" width={200}>
				<Menu.Target>
					<button
						type="button"
						className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none mr-2"
					>
						<EllipsisVerticalIcon className="w-4 h-4" />
					</button>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Label>Custom part</Menu.Label>
					<Menu.Item
						type="button"
						icon={<PlusIcon className="w-4 h-4" />}
						onClick={actions.menu.custom.add}
					>
						Add part
					</Menu.Item>
					<Menu.Divider />
					<Menu.Label>Diagram</Menu.Label>
					<Menu.Item
						type="button"
						icon={<ArrowsPointingInIcon className="w-4 h-4" />}
						onClick={actions.menu.diagram.recenter}
					>
						Re-center
					</Menu.Item>
					<Menu.Item
						type="button"
						icon={<MagnifyingGlassIcon className="w-4 h-4" />}
						onClick={actions.menu.diagram.resetZoom}
					>
						Reset Zoom
					</Menu.Item>
					<Menu.Item
						type="button"
						color="red"
						className="group"
						icon={<ArrowUturnLeftIcon className="w-4 h-4" />}
						onClick={actions.menu.diagram.resetAll}
					>
						Reset View
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</div>
	);
};
