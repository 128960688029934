import { CartSubtotalPrice, ListingPricing as Pricing } from '@sdk/lib';

export type DisplayPricing = {
	displayPrice: string;
	originalPrice?: string;
	percentOff?: number;
};

export const DEFAULT_CURRENCY = 'NZD';

export const formatPrice = (price: number, locale: string = DEFAULT_CURRENCY) => {
	if (price === 0) {
		return 'Price on request';
	}

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: locale
	});

	return formatter.format(price / 1000);
};

export const createMoney = (amount: number) => {
	return amount * 10 ** 3;
};

/**
 * Calculate the sale price, original price and percent off for
 * product.
 *
 * Assumptions
 * + min and max are always the same
 * + displayMin and displayMax are always the same
 *
 * We make those assumations because this is a single product.
 */
export const calculateDisplayPricing = (pricing: Pricing): DisplayPricing => {
	// In future, locale will be passed in with pricing.
	const currency = 'NZD';
	const displayPrice = pricing.min;
	const originalPrice = pricing.displayMin;

	if (originalPrice === null || originalPrice === undefined) {
		return {
			displayPrice: formatPrice(displayPrice, currency)
		};
	}

	const percentOff = Math.round(((originalPrice - displayPrice) / originalPrice) * 100);

	return {
		displayPrice: formatPrice(displayPrice, currency),
		originalPrice: formatPrice(originalPrice, currency),
		percentOff
	};
};

export const formatSubtotal = (subtotal: CartSubtotalPrice | null): string => {
	if (!subtotal) {
		return '--';
	}

	if (subtotal.displayPrice) {
		return formatPrice(subtotal.displayPrice, subtotal.currency);
	}

	return formatPrice(subtotal.price, subtotal.currency);
};
