import { isDefined } from '@/app/utils/common';
import { formatPartContext } from '@/app/utils/part';
import { draft_order, order, part_slot, supply } from '@/sdk/reflect/reflect';
import {
	GetJobSupplyRecommendationsResult,
	JobPart,
	PartSupplyOffer,
	SupplyItemOffer,
	SupplyVendor
} from '@sdk/lib';
import { DraftOrderSelection, OrderPartSupplyOffer } from './models';
import { createSelectionFromSupply, restoreOrderSelection } from './order-request';

export const createInitialSelection = (
	draft_orders: draft_order.exp.DraftOrder[],
	orders: order.exp.Order[],
	recommendation: GetJobSupplyRecommendationsResult,
	repairer_site_id: string
): DraftOrderSelection => {
	// Creating a new initial selection.
	const selection: DraftOrderSelection = {
		delivery_date: new Date(),
		repairer_site_id,
		attempt_auto_transition_order: false,
		draft_orders: {}
	};

	// restore any saved / existing draft order
	if (draft_orders.length > 0) {
		return restoreOrderSelection(selection, draft_orders);
	}

	// create no draft order unless user selects supplies when there's a finalised/completed order (additional order step)
	if (orders.length > 0) {
		return restoreOrderSelection(selection, draft_orders);
	}

	// creates a draft order by auto-selecting supplies for job parts
	return createSelectionFromSupply(selection, recommendation);
};

export const createPartSelectionContext = (
	ctx: part_slot.exp.PartSelectionContext | null | undefined
): part_slot.PartSelectionContext | null => {
	if (!ctx) {
		return null;
	}

	const newContext: part_slot.PartSelectionContext = {
		description: ctx.description,
		gapc_brand_id: ctx.gapc_brand?.id ?? undefined,
		gapc_part_type_id: ctx.gapc_part_type?.id ?? undefined,
		gapc_position_id: ctx.gapc_position?.id ?? undefined,
		mpn: ctx.mpn ?? undefined
	};

	return newContext;
};

export const createPartSelectionContexts = (
	context: part_slot.exp.PartSelectionContexts | null | undefined
): part_slot.PartSelectionContexts | null => {
	if (!context) {
		return null;
	}

	return context.map(ctx => createPartSelectionContext(ctx)).filter(isDefined);
};

export const compareOfferIds = (a: SupplyItemOffer, b: supply.SupplyItemOffer) => {
	if (a.type === 'Product' && b.type === 'Product') {
		return a.offerId === b.offer_id;
	}

	if (a.type === 'Kit' && b.type === 'Kit') {
		if (a.offerIds.length !== b.offer_ids.length) {
			return false;
		}

		// typescript forgets that b is a Kit here
		return a.offerIds.every(offerId => b.type === 'Kit' && b.offer_ids.includes(offerId));
	}

	return false;
};

export const createAllVendors = (supplyOffers: PartSupplyOffer[]): SupplyVendor[] => {
	const allSuppliers = supplyOffers.reduce((acc: Map<string, SupplyVendor>, offer) => {
		if (acc.has(offer.vendor.id)) {
			return acc;
		}

		acc.set(offer.vendor.id, offer.vendor);

		return acc;
	}, new Map<string, SupplyVendor>());

	return [...allSuppliers.values()].sort((a, b) => a.name.localeCompare(b.name));
};

export const createJobPartName = (part: JobPart): string => {
	const partName = formatPartContext(
		part.partSlot?.gapcPartType ?? null,
		part.partSlot?.gapcPosition ?? null,
		part.description,
		part.mpn
	);

	if (!partName) {
		return 'N/A';
	}

	return partName;
};

export const sortOffers = (
	offers: OrderPartSupplyOffer[],
	recommendedOfferId: string | undefined
) => {
	const recommendedOffer = offers.find(offer => offer.id === recommendedOfferId);
	const rest = offers.filter(offer => offer.id !== recommendedOfferId);

	return [recommendedOffer, ...rest.sort((a, b) => a.price.price - b.price.price)].filter(
		isDefined
	);
};

/**
 * TODO supply recommendation algorithm needs to support kits, as a kit can fulfill multiple job parts
 */
export const anyOfferFulfillsMultipleJobParts = (
	recommendationData: GetJobSupplyRecommendationsResult
): boolean => {
	for (const offerId in recommendationData.offers) {
		const offer = recommendationData.offers[offerId];
		if (offer.gapcParts.length > 1) {
			return true;
		}
	}

	return false;
};
