import { PartsCart } from '@/app/features/parts/components/parts-cart';
import { PartsCustomPart } from '@/app/features/parts/components/parts-custom-part';
import { PartsDiagram } from '@/app/features/parts/components/parts-diagram';
import { PartsSpotlight } from '@/app/features/parts/components/parts-spotlight';
import { useUpsertParts } from '@/app/features/parts/hooks/use-upsert-parts';
import { PartsFormData, PartsSelection } from '@/app/features/parts/types';
import { categoriesDiagrams, categoryLeaves } from '@/app/features/parts/utils';
import { createInitialPartsFormData } from '@/app/features/parts/utils/form';
import { filterCategories, filterOtherCategory } from '@/app/features/parts/utils/search';
import { withSignedIn } from '@/app/hoc/with-access';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { useMeasurement } from '@/app/hooks/use-measure';
import { useSearchQueries } from '@/app/hooks/use-search-queries';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { RepairhubEvents } from '@/app/utils/analytics/events';
import { decodeGapcPartIdentityKey, encodeGapcPartIdentityKey, JobPartInput } from '@/sdk/lib';
import { isDefined } from '@/sdk/lib/utils/object';
import { jobsQueries } from '@/sdk/react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { useSuspenseQueries } from '@tanstack/react-query';
import { compact, entries, groupBy, sortBy, sum, values } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { JobMainAction, JobSubActions } from '../job-detail';

type PageParams = {
	jobId: string;
};

const PartsPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { value: nav } = useMeasurement('navigation-bar');
	const navigate = useNavigate();

	const [queries, setQueries] = useSearchQueries([
		'q',
		'diagram',
		'category',
		'cart',
		'highlighted'
	]);

	const [customPart, setCustomPart] = useState(false);
	const [navigating, setNavigating] = useState(false);
	const [searching, setSearching] = useState(false);

	const { logEvent } = useAnalytics();
	const { mutateAsync: upsertParts } = useUpsertParts();
	const [{ data: assembliesData }, { data: partsData }] = useSuspenseQueries({
		queries: [jobsQueries.getPartAssembliesTree({ jobId }), jobsQueries.listParts({ jobId })]
	});

	const { categories, other, resources } = useMemo(
		() => categoriesDiagrams(assembliesData.tree.assemblies),
		[assembliesData]
	);

	const { control, handleSubmit, watch, formState, reset, setValue } = useForm<PartsFormData>({
		defaultValues: createInitialPartsFormData(partsData.parts, resources)
	});

	const selection = watch();
	const { isDirty, isSubmitting } = formState;

	const filtered = useMemo(
		() => ({
			categories: filterCategories(categories, queries.q),
			other: filterOtherCategory(other, queries.q)
		}),
		[categories, queries.q]
	);

	const leaves = useMemo(() => {
		const leaves = filtered.categories.flatMap(categoryLeaves);
		if (filtered.other) {
			leaves.push(filtered.other);
		}
		return leaves;
	}, [filtered, other]);

	const category = useMemo(
		() => leaves.find(({ id }) => id === queries.category),
		[leaves, queries]
	);

	const diagram = useMemo(
		() => category?.diagrams.find(({ id }) => id === queries.diagram),
		[category, queries]
	);

	const partSlot = useMemo(() => {
		return diagram?.partSlots.find(({ id }) => queries.highlighted === id);
	}, [diagram, queries]);

	const count = useMemo(
		() =>
			values(selection)
				.filter(isDefined)
				.filter(({ quantity }) => quantity > 0).length,
		[selection]
	);

	const gapcBrandId = useMemo(() => {
		return [...resources.assemblies.values()][0].part.gapcBrandId;
	}, [resources]);

	const onSubmit = useCallback(
		async (data: PartsFormData) => {
			const selection = sortBy(compact(values(data)), ({ order }) => order ?? 0);
			if (isDirty) {
				const parts = entries(
					groupBy(selection, ({ gapcBrandId, mpn }) =>
						encodeGapcPartIdentityKey({ gapcBrandId, mpn })
					)
				).map(
					([partIdentity, selection]): JobPartInput => ({
						...decodeGapcPartIdentityKey(partIdentity),
						quantity: sum(selection.map(({ quantity }) => quantity)),
						description: selection[0].description,
						partSlot: selection[0].partSlotIds?.gapcPartTypeId
							? {
									gapcPartTypeId: selection[0].partSlotIds.gapcPartTypeId,
									gapcPositionId: selection[0].partSlotIds.gapcPositionId
								}
							: null,
						assemblyIds: selection.map(({ assemblyId }) => assemblyId).filter(isDefined)
					})
				);

				await upsertParts({
					jobId,
					parts
				});

				logEvent(
					RepairhubEvents.job_parts_upserted({
						job_id: jobId,
						parts
					})
				);
				// retain changes (job parts won't immediately get updated, should prevent added parts from flashing to default values)
				reset(undefined, { keepValues: true });
			}

			const hasAddedParts = sum(selection.map(selection => selection?.quantity ?? 0)) > 0;
			if (hasAddedParts) {
				navigate(`/job/${jobId}/supply`);
			}
		},
		[jobId, isDirty, upsertParts, reset, logEvent]
	);

	useUnsavedChanges(isDirty && !isSubmitting);

	useEffect(() => {
		if (!diagram || !partSlot) {
			return;
		}
		for (const assembly of partSlot.assemblies) {
			logEvent(
				RepairhubEvents.job_part_diagram_interaction({
					job_id: jobId,
					diagram: { name: diagram.description, code: diagram.code, part_slot: partSlot.pnc },
					part: {
						mpn: assembly.part.mpn,
						description: assembly.description,
						gapc_brand_id: assembly.part.gapcBrandId
					}
				})
			);
		}
	}, [partSlot]);

	return (
		<form
			id="parts-form"
			className="flex items-center place-items-center"
			noValidate
			onSubmit={handleSubmit(onSubmit)}
			data-testid="parts-section"
			style={{
				height: `calc(100dvh - ${nav?.height ?? 0}px)`
			}}
		>
			<JobMainAction>
				<PartsSpotlight
					categories={categories}
					other={other}
					open={searching}
					search={queries.q ?? ''}
					actions={{
						view: setSearching,
						filter: (q: string) => {
							if (!q) {
								setQueries({ q });
								return;
							}
							setQueries({
								q,
								category: null,
								diagram: null,
								highlighted: null
							});
						},
						jump: {
							category: category => {
								setNavigating(false);
								setQueries({ category, diagram: null, highlighted: null });
							},
							diagram: (category, diagram) => {
								setNavigating(false);
								setQueries({ category, diagram, highlighted: null });
							},
							part: (category, diagram, partSlot) => {
								setNavigating(false);
								setQueries({ category, diagram, highlighted: partSlot });
							}
						},
						add: assembly => {
							const data: PartsSelection = {
								mpn: assembly.part.mpn,
								gapcBrandId: assembly.part.gapcBrandId,
								partSlotIds: assembly.partSlotIds,
								quantity: 1,
								assemblyId: assembly.id,
								description: assembly.description,
								hcas: assembly.hcas,
								order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
							};

							setValue(assembly.id, data);
						}
					}}
				/>
			</JobMainAction>
			<JobSubActions>
				<Button
					key="parts-cart-button"
					className="w-fit ml-auto"
					type="button"
					onClick={() => setQueries({ cart: !queries.cart ? '1' : null })}
				>
					Cart
					<ShoppingBagIcon className="w-4 h-4 mx-1.5" />
					{count}
				</Button>
			</JobSubActions>
			<PartsDiagram
				className="flex-shrink-0"
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
				}}
				categories={filtered.categories}
				other={filtered.other}
				category={category}
				diagram={diagram}
				partSlot={partSlot}
				control={control}
				highlighted={queries.highlighted}
				selection={selection}
				navigating={navigating}
				actions={{
					view: { set: setNavigating },
					part: {
						highlight: id => {
							setQueries({
								highlighted: queries.highlighted !== id ? id : null
							});
						}
					},
					category: {
						set: category => setQueries({ category: category.id, diagram: null, highlighted: null })
					},
					diagram: {
						set: diagram => setQueries({ diagram: diagram.id, highlighted: null })
					},
					custom: {
						add: () => setCustomPart(true)
					}
				}}
			/>

			<PartsCart
				form="parts-form"
				control={control}
				selection={selection}
				categories={leaves}
				diagram={diagram}
				partSlot={partSlot}
				open={!!queries.cart}
				changed={isDirty}
				actions={{
					close: () => setQueries({ cart: null }),
					part: {
						highlight: id => {
							setQueries({
								highlighted: queries.highlighted !== id ? id : null
							});
						},
						jump: (category, diagram, partSlot) =>
							setQueries({ category, diagram, highlighted: partSlot })
					}
				}}
			/>

			<PartsCustomPart
				id="parts-custom-part"
				className="z-[90]"
				gapcBrandId={gapcBrandId}
				open={customPart}
				resources={resources}
				onSubmit={({ mpn, description, id, assembly }) => {
					const data: PartsSelection = {
						gapcBrandId,
						mpn,
						order: count,
						partSlotIds: assembly?.partSlotIds ?? null,
						quantity: 1,
						assemblyId: assembly?.id ?? id,
						hcas: assembly?.hcas ?? [],
						description: assembly?.description ?? description
					};

					const selectionId = assembly?.id ?? id ?? v4();
					setValue(selectionId, data);
					setCustomPart(false);
				}}
				onClose={() => setCustomPart(false)}
			/>
		</form>
	);
};

export default withSignedIn(PartsPage);
